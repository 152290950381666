
@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');

  

.syarat {
  font-family: 'Jua', sans-serif;
}


.iconpoonya{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
}


  .space {
    margin-top: 5px;
  }
  
  .clear {
    clear: both;
  }
  
  .center {
    text-align: center;
  }
  
  .no-margin {
    margin: 0!important;
  }
  
  img {
    max-width: 100%;
  }
  
  .max-width {
    max-width: 940px;
    width: 90%;
    margin: 0 auto;
  }
  
  .payment {
    padding: 5%;
    position: relative;
    top: 15vh;
    box-shadow: 15px 15px 0px -4px rgba(0, 0, 0, 0.3), 0px 0px 85px 0px rgba(0, 0, 0, 0.4), ;
  ;
  }
  
  .turquoise {
    background-color: #EEEEEE;
    color: #351b1b;
   
  }
  
  .headline {
    position: relative;
    margin-bottom: 50px;
    border-bottom: 1px solid;
    line-height: 30px;
  }
  
  .headline abbr {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    padding: 0 15px;
    background: #fff;
    top: 15px;
  }
  
  p {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }
  
  strong {
    font-weight: 900;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
   font-family: 'Jua', sans-serif;
   
    font-weight:600;
    letter-spacing: 0.03em;
  }
  
  h1 {
    font-size: 30px;
  }
  
  h2 {
    font-size: 24px;
  }
  
  h3 {
    font-size: 20px;
  }
  
  h4 {
    font-size: 16px;
  }
  
  h5 {
    font-size: 14px;
  }
  
  .upper-bryant {
   font-family: 'Jua', sans-serif;
   
  }
  
  .small-bold {
    font-weight: 600;
    font-size: 14px;
  }
  
  .micro {
    font-size: 9px;
    letter-spacing: 0.4px;
  }
  
  .tiny {
    font-size: 12px;
  }
  
  .huge {
    font-size: 22px;
  }
  
  .dark-content-box {
    background: rgba(0, 0, 0, 0.25);
    padding: 5%;
    color: #fff;
  }
  
  hr {
    border-top: 1px solid;
    border-bottom: 0;
  }
  
  input,
  textarea {
    width: 70%;
    width: calc(80% - 2px);
    border-radius: 2px;
    border: 0;
    font-size: 18px;
    padding: 10px 0;
   font-family: 'Jua', sans-serif;
    
    transition: box-shadow 0.3s;
  }
  
  input:focus,
  textarea:focus,
  input[type=number]:focus {
    box-shadow: 0 0 0 1pt #416051;
    outline: none;
  }
  
  textarea {
    min-height: 75px;
  }
  
  input,
  textarea {
   height: 50%;
    padding: 10px;
    width: 90%;
  }
  
  textarea.btn,
  input.btn {
    padding: 12px 0;
    margin: 10px 0;
    width: 100%;
   
  }
  
  /* section {
    margin: 20px 0;
  } */
  
  .cvc-box {
    position: relative;
  }
  
  .cvc {
    position: relative;
    top: 20px;
    height: 44px;
  }
  
  p .pf {
    font-size: 2vw;
    opacity: 0.7;
    margin: 0 0.5% 1%;
  }
  
  .cards {
    text-align: center;
    border: 1px solid;
  }
  
  .cards h6 {
    margin: -5px auto 5px;
    background: #849E91;
    width: 52%;
  }
  
  .dark-content-box.payment-info {
    min-height: 183px;
  }
  
  .dark-content-box.payment-info p {
    padding: 8px 0;
    font-size: 15px;
    font-weight: 100;
    line-height: 22px;
  }
  
  .tall-slash {
    font-size: 32px;
    font-weight: 100;
    padding-top: 10px;
    display: block;
  }


  /* CSS */
.button-1 {
  background-color: #FF8282;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  line-height: 10px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 13px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  display: flex;
  
}

.button-1:hover,
.button-1:focus {
  background-color: #FF8282;
}
.button-2 {
  background-color: #41BBFF;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  line-height: 10px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  
}

.button-2:hover,
.button-2:focus {
  background-color: #41BBFF;
}

.button-3 {
  background-color: #41BBFF;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 35px;
  line-height: 10px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: 'Jua', sans-serif;
  
}

.button-3:hover,
.button-3:focus {
  background-color: #41BBFF;
}

.button-4 {
  background-color: #41BBFF;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 50px;
  line-height: 10px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: 'Jua', sans-serif;
  width: 50%;
  text-align: center;
  align-items: center;
  align-content: center;
  align-self: center;
  
}

.button-4:hover,
.button-4:focus {
  background-color: #41BBFF;
}
  

  input[type=text], select {
   width: 50%;
   padding: 5px 10px;
   margin: 8px 0;
   display: inline-block;
   /* border: 1px solid #ccc;
   border-radius: 4px;
   box-sizing: border-box; */
 
 
}
 

  #card-number-email{
    width: 100%;
    
    padding: 5px 10px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 20;
  }

  #card-number-wa{
    width: 100%;
    
    padding: 5px 10px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 20;
  }


    input[type=number], select {
    width: 100%;
    padding: 1px 5px 0px 30px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    text-transform: uppercase;
    
  
  }

  ::placeholder {
    color: rgb(204, 195, 195);
    opacity: 1; /* Firefox */
  }
  
