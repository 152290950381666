html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    position: relative;
}


img.logo {
    position: relative;
    
    
    margin-top: 50%;
  
}